// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-book-us-index-js": () => import("./../../../src/pages/book-us/index.js" /* webpackChunkName: "component---src-pages-book-us-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-the-band-index-js": () => import("./../../../src/pages/the-band/index.js" /* webpackChunkName: "component---src-pages-the-band-index-js" */),
  "component---src-pages-what-we-play-index-js": () => import("./../../../src/pages/what-we-play/index.js" /* webpackChunkName: "component---src-pages-what-we-play-index-js" */)
}

